import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { hideAlert } from "../../../store/alerts/alertActions";
import cx from "classnames";
import styles from "./alert.module.scss";
import CloseIcon from "../../../assets/iconsLibrary/simple/close/closeIcon";
import { gaColors } from "../../../data/display";
import AlertIcon from "../../../assets/iconsLibrary/simple/alert/alertIcon";
import { ALERT_ICONS, ALERT_TYPES } from "../../../store/alerts/alertTypes";
import InfoIcon from "../../../assets/iconsLibrary/simple/info/infoIcon";

type IAlertComponentProps = {
  kind: ALERT_TYPES;
  title?: string;
  subtitle?: string;
  trailingIcon?: ALERT_ICONS;
  closeButton?: boolean;
  classname?: string;
};

const Alert: React.FC<IAlertComponentProps> = (props) => {
  const { kind, title, subtitle, trailingIcon, closeButton, classname } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {}, [subtitle]);

  const closeAlert = () => dispatch(hideAlert());

  const getAlertTypeClass = () => {
    let typeClass = styles.error;

    if (kind) {
      switch (kind) {
        case ALERT_TYPES.ERROR:
          typeClass = styles.error;
          break;

        case ALERT_TYPES.INFO:
          typeClass = styles.info;
          break;
        // TODO: This could be util in the future
        // case ALERT_TYPES.SUCCESS:
        //   typeClass = styles.success
        //   break;
        // case ALERT_TYPES.WARNING:
        //   typeClass = styles.warning
        //   break;
        default:
          typeClass = styles.error;
      }
    }

    return typeClass;
  };

  const getTrailingIconColor = (): string => {
    let iconColor = gaColors.alert300;
    if (kind) {
      switch (kind) {
        case ALERT_TYPES.ERROR:
          iconColor = gaColors.alert300;
          break;
        case ALERT_TYPES.INFO:
          iconColor = gaColors.info300;
          break;
        default:
          iconColor = gaColors.alert300;
      }
    }

    return iconColor;
  };

  const getTrailingIcon = () => {
    let TrailingIcon: ReactElement | undefined = (
      <AlertIcon size={24} color={gaColors.alert300} />
    );

    if (trailingIcon) {
      switch (trailingIcon) {
        case ALERT_ICONS.ALERT:
          TrailingIcon = <AlertIcon size={24} color={getTrailingIconColor()} />;
          break;
        case ALERT_ICONS.INFO:
          TrailingIcon = <InfoIcon size={24} color={getTrailingIconColor()} />;
          break;
        // TODO: This could be util in the future
        // case ALERT_ICONS.CHECK:
        //   TrailingIcon = <AlertIcon size={24} color={getTrailingIconColor()} />;
        //   break;
        // case ALERT_ICONS.CHECK_CIRCLE:
        //   TrailingIcon = <AlertIcon size={24} color={getTrailingIconColor()} />;
        //   break;
        default:
          TrailingIcon = <AlertIcon size={24} color={getTrailingIconColor()} />;
      }
    } else {
      TrailingIcon = undefined;
    }

    return TrailingIcon;
  };

  const TrailingIcon = getTrailingIcon();
  const typeClass = getAlertTypeClass();

  return (
    <div
      className={`${styles.gaAlertPanel} ${typeClass ? typeClass : ""} ${classname ? classname : ""}`}>
      <div className={styles.content}>
        {TrailingIcon ? (
          <div className={`${styles.trailingIcon} `}>{TrailingIcon}</div>
        ) : null}

        <div className={`${styles.texts}`}>
          {title ? (
            <p className={`${styles.title} ${cx("bodyBoldSM neutral1000")}`}>
              {t(title)}
            </p>
          ) : null}

          {subtitle && (
            <p className={cx("bodyRegularSM neutral1000")}>{t(subtitle)}</p>
          )}
        </div>
        {closeButton && (
          <div className={`${styles.close} `} onClick={() => closeAlert()}>
            <CloseIcon
              className={styles.closeIcon}
              size={24}
              color={gaColors.neutral1000}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Alert;
