import * as React from "react";
import styles from "./loadingQR.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { LoaderSizes } from "../../../../../data/display";
import AnimatedLoader from "../../../../atoms/loaders/animatedLoader/AnimatedLoader";

type LoadingQRProps = {
  className?: string;
  text?: string;
};

const LoadingQR: React.FC<LoadingQRProps> = (props) => {
  const { className, text } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={`${styles?.card} ${cx(className && className)}`}>
        <div>
          <AnimatedLoader size={LoaderSizes.MD} />
        </div>
        <div>
          <p className={`${styles?.text} ${cx("neutral100")}`}>
            {t(text || "general.loadingQRCode")}
          </p>
        </div>
      </div>
    </>
  );
};

export default LoadingQR;
