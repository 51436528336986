import * as React from "react";
import cx from "classnames";
import styles from "./useCaseCard.module.scss";
import { UseCaseHomeCardModel } from "../../../../interfaces/interfaces";
import GaImage from "../../../../components/atoms/Images/gaImage";
import { t } from "i18next";
import PurpleButton from "../../../../components/atoms/buttons/purple/PurpleButton";
import { ButtonSizes } from "../../../../data/display";
import { setSelectedUseCase } from "../../../../store/useCases/useCasesActions";
import { useDispatch } from "react-redux";
import { navigateToAction } from "../../../../store/navigation/navigationActions";
import { setSessionStorageData } from "../../../../services/sessionStorageService";
import { initialLang } from "../../../../i18n";

interface IUseCardProps {
  id: string;
  data: UseCaseHomeCardModel;
  path: string;
}

const UseCaseCard: React.FC<IUseCardProps> = (props) => {
  const { image, title, by, holder, verifier } = props?.data;
  const dispatch = useDispatch();

  const id = props?.id;
  const UCPach = props?.path;

  const goToDownloadWallet = () => {
    setSessionStorageData("ga__UC", id);
    dispatch(setSelectedUseCase(id));
    dispatch(navigateToAction(`/demo/${UCPach}/download-wallet`));
  };

  const esIsInitialLang = initialLang === "es";

  return (
    <div className={styles.useCaseCard}>
      <div className={styles.imageContainer}>
        <GaImage image={image} />
      </div>

      <p className={`${cx("heading5 marginBottom4 marginTop32")}`}>
        {t(title)}
      </p>
      <p className={`${cx("bodyRegularSM neutral700 marginTopAuto")}`}>
        {t(by)}
      </p>

      <div className={`${styles.tagsContainer}`}>
        <div className={`${styles.tag}`}>
          <p className={`${cx("buttonSM")}`}>
            {t("home.secondSection.holder")}
          </p>
          <p className={`${cx("bodyRegularCap neutral500")}`}>{t(holder)}</p>
        </div>
        <div className={`${styles.tag} ${esIsInitialLang && styles.largeTag}`}>
          <p className={`${cx("buttonSM")}`}>
            {t("home.secondSection.verifier")}
          </p>
          <p className={`${cx("bodyRegularCap neutral500")}`}>{t(verifier)}</p>
        </div>
      </div>

      <PurpleButton
        className={`${styles.button}`}
        label={t("home.secondSection.cardButton")}
        size={ButtonSizes.MD}
        action={goToDownloadWallet}
      />
    </div>
  );
};

export default UseCaseCard;
